import { Controller } from 'stimulus'
import { tns } from '../../../node_modules/tiny-slider/src/tiny-slider'

export default class CarouselController extends Controller {
  static targets = ['container', 'controls']

  connect () {
    tns({
      container: this.containerTarget,
      mode: 'carousel',
      items: 1,
      slideBy: 1,
      autoplay: true,
      autoplayHoverPause: true,
      controlsContainer: this.controlsTarget
    })
  }
}
