import { Controller } from 'stimulus'

const INFORMACION_GENERAL = 'informacion general'
const QUEJA = 'queja'

export default class ContactFormController extends Controller {
  static targets = [
    'generalInfoContainer',
    'generalInfoField',
    'complaintContainer',
    'complaintField'
  ]

  updateFields (event) {
    if (event.target.value === INFORMACION_GENERAL) {
      this.generalInfoContainerTarget.classList.remove('is-hidden')
      this.complaintContainerTarget.classList.add('is-hidden')

      this.generalInfoFieldTargets.forEach(target => {
        target.setAttribute('required', true)
      })
      this.complaintFieldTargets.forEach(target => {
        target.removeAttribute('required')
      })
    } else if (event.target.value === QUEJA) {
      this.generalInfoContainerTarget.classList.add('is-hidden')
      this.complaintContainerTarget.classList.remove('is-hidden')

      this.generalInfoFieldTargets.forEach(target => {
        target.removeAttribute('required')
      })
      this.complaintFieldTargets.forEach(target => {
        target.setAttribute('required', true)
      })
    }
  }
}
