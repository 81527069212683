import { Controller } from 'stimulus'
import L from 'leaflet'
import { GeoSearchControl, OpenStreetMapProvider } from 'leaflet-geosearch'

const DEFAULT_POS = { lat: 24.0227727, lng: -104.6827442 }
const DEFAULT_ZOOM = {
  init: 13,
  min: 10,
  max: 16
}
const ICONMAKER = L.icon({
  iconUrl: '/assets/icons/map-marker-icon-48x48-shadow.png',
  iconSize: [64, 64],
  iconAnchor: [48, 48]
})
export default class LocationsMapController extends Controller {
  static targets = ['container', 'restaurant', 'franchisee']

  async connect () {
    this.tilesProvider =
      'https://2.base.maps.ls.hereapi.com/maptile/2.1/maptile/newest/normal.night.grey/{z}/{x}/{y}/256/png?apiKey=' +
      this.data.get('key')
    this.franchisees = this.loadFranchisees()
    this.restaurants = this.loadRestaurants()
    this.initializeMap()
  }

  initializeMap () {
    this.createtMap()
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(position => {
        this.setMapPosition(position.coords.latitude, position.coords.longitude)
      })
    }
  }

  createtMap () {
    this.myMap = L.map(this.containerTarget, {
      center: [DEFAULT_POS.lat, DEFAULT_POS.lng],
      zoom: DEFAULT_ZOOM.init,
      minZoom: DEFAULT_ZOOM.min,
      maxZoom: DEFAULT_ZOOM.max,
      continuousWorld: false,
      noWrap: true,
      scrollWheelZoom: false
    })

    L.tileLayer(this.tilesProvider, {
      maxZoom: DEFAULT_ZOOM.max
    }).addTo(this.myMap)

    this.setRestaurants(DEFAULT_POS.lat, DEFAULT_POS.lng)
    const provider = new OpenStreetMapProvider()
    const search = new GeoSearchControl(
      {
        provider: provider,
        style: 'bar',
        showMarker: false,
        autoClose: true,
        searchLabel: 'Ingresa tu ciudad o dirección'
      },
      function (event) {}
    )
    this.myMap.addControl(search)
    this.myMap.on('moveend', event => {
      this.setRestaurants(
        event.target.getBounds()._southWest.lat,
        event.target.getBounds()._southWest.lng
      )
    })
  }

  setRestaurants (lat, long) {
    let restaurantsNearby = this.findNearbyRestaurants(lat, long)
    restaurantsNearby.forEach(restaurant => {
      const franchisee = this.franchisees[restaurant.franchisee]
      this.addMarker(restaurant, franchisee.phone)
    })
  }

  setMapPosition (lat, long) {
    this.myMap.panTo(new L.LatLng(lat, long))
    this.setRestaurants(lat, long)
  }

  addMarker (restaurant, phone) {
    L.marker([restaurant.lat, restaurant.lng], { icon: ICONMAKER }).addTo(
      this.myMap
    ).bindPopup(`
      <div class="info-window">
        <h5 class="title is-5">${restaurant.name}</h5>
        <p class="subtitle is-5">${restaurant.address}</p>

        <h6 class="title is-6">Teléfono</h6>
        <p class="subtitle is-6">${phone}</p>

        <a href="${restaurant.url}" class="button is-red is-small is-bold">Ir a Restaurante</a>
      </div>
        `)
  }

  loadFranchisees () {
    return this.franchiseeTargets.reduce((accumulator, target) => {
      accumulator[target.dataset.name] = {
        name: target.dataset.name,
        facturacion: target.dataset.facturacion,
        phone: target.dataset.phone
      }

      return accumulator
    }, {})
  }

  loadRestaurants () {
    return this.restaurantTargets.map(target => {
      const data = target.dataset

      return {
        name: data.name,
        address: data.address,
        state: data.state,
        lat: parseFloat(data.lat),
        lng: parseFloat(data.lng),
        mapsPlaceId: data.mapsPlaceId,
        franchisee: data.franchisee,
        url: data.url
      }
    })
  }

  findNearbyRestaurants (lat, lng) {
    return this.restaurants.reduce((acc, rest) => {
      const isNearby =
        rest.lat > lat - 1 &&
        rest.lat < lat + 1 &&
        rest.lng > lng - 1 &&
        rest.lng < lng + 1

      if (isNearby) {
        acc.push(rest)
      }
      return acc
    }, [])
  }
}
