import { Controller } from 'stimulus'

export default class UpmenuLoadingController extends Controller {
  static targets = ['spinner']

  connect () {
    this.spinnerVisible = true
    this.observer = new MutationObserver(this.nodeChanged.bind(this))
    this.observer.observe(this.element, {
      attributes: true,
      childList: true,
      subtree: true
    })
  }

  disconnect () {
    this.observer.disconnect()
  }

  nodeChanged = () => {
    if (this.spinnerVisible) {
      this.spinnerTarget.classList.add('is-hidden')
      this.spinnerVisible = false
    }
  }
}
